.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.install-prompt {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
}

.install-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
    border: 2px solid white;
    padding: 15px 30px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
    min-width: 200px;
}

.install-button:hover {
    background-color: white;
    color: black;
}

.install-icon {
    margin-right: 10px;
    font-size: 24px;
}

@media (max-width: 600px) {
    .install-button {
        width: 100%;
        max-width: 300px;
    }
}
